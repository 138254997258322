import Request from '@/utils/request'

export function getOutboundApi (data) {
  return Request({
    url: '/model/list',
    params: data
  })
}

export function saveOutboundApi (data) {
  return Request({
    url: '/model/add',
    method: 'post',
    data
  })
}

export function getAllTypeApi () {
  return Request({
    url: '/product/type/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/product/specsbytype',
    params: data
  })
}

export function getOutboundDetailApi (data) {
  return Request({
    url: '/model/detail',
    params: data
  })
}

export function getTypesApi () {
  return Request({
    url: '/product/type'
  })
}

export function delOutboundApi (data) {
  return Request({
    url: '/model/delete',
    params: data
  })
}
