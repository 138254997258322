<template>
  <div v-loading="loading" class="stockList">
    <div class="top">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane
          v-for="(item, index) in typeList"
          :key="index"
          :label="`${item.productTypeName}`"
          :name="item._id"
        ></el-tab-pane>
      </el-tabs>
      <el-button @click="handleAdd" type="primary" style="margin-right: 20px">新增模板</el-button>
      <el-select v-if="activeName !== 'all'" clearable filterable placeholder="全部规格" v-model="specsFilter" @change="getList()" style="margin-right: 20px">
        <el-option v-for="(item,index) in specsList" :key="index" :label="item.productSpecsName" :value="item._id"></el-option>
      </el-select>
      <el-select clearable placeholder="全部类型" style="margin-right: 20px" v-model="outboundTypeFilter" @change="getList()">
        <el-option label="入库" :value="1"></el-option>
        <el-option label="出库" :value="2"></el-option>
        <el-option label="报废" :value="3"></el-option>
      </el-select>

      <el-date-picker
        style="margin: 0 20px;"
        v-model="dateFilter"
        type="daterange"
        format="YYYY/MM/DD"
        value-format="YYYY-MM-DD"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="getList()"
      />
      <!-- <el-button @click="exportDialogShow = true" type="primary" style="margin-right: 20px">导出Excel</el-button> -->

    </div>
    <el-table ref="table" class="table" :height="tableH" :data="tableData" :row-class-name="tableRowClassName">
      <el-table-column prop="date" label="日期"></el-table-column>
      <el-table-column prop="type" label="类别" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.type}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="specs" label="规格" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.specs}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="banhao" label="版号" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.banhao}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="banzhou" label="版周" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.banzhou}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="数量" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.quantity}}</span>
        </template>
      </el-table-column>

      <el-table-column label="类型" prop="banType">
        <template v-slot="scope">
          <span :class="scope.row.banType === 3 ? 'red': scope.row.banType === 2 ? 'green':''"> {{ scope.row.banType === 1 ? "入库" :scope.row.banType === 2 ? "出库" : "报废" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.remark}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180" >
        <template v-slot="scope">
          <span :class="scope.row.outboundType === 2 ? 'red': scope.row.outboundType === 3 ? 'grey':''">{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px" >
        <template #default="scope">
          <!-- <el-button type="text" @click="handleEdit(scope.row._id)">编辑</el-button> -->
          <el-button type="text" @click="handleDel(scope.row._id)" style="color:#F56C6C;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      :page-size="20"
      layout="total, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    />
    <EditDialog
      v-if="editDialogShow"
      :id="currentId"
      :refresh-list="getList"
      @close="handleDialogclose"
    />
    <!-- <ExportDialog v-if="exportDialogShow" @close="exportDialogShow = false"></ExportDialog> -->
  </div>
</template>

<script>
import { reactive, toRefs, ref, nextTick, onMounted, inject } from 'vue'
import EditDialog from './edit.vue'
import {
  getOutboundApi,
  getTypesApi,
  delOutboundApi,
  getSpecByTypeApi
  // getBuyerListApi
} from './fetch'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store'
// import ExportDialog from './exportDialog.vue'
export default {
  name: 'Home',
  components: {
    EditDialog
    // ExportDialog
  },
  setup () {
    const userInfo = store.state.userInfo
    const $msg = inject('$msg')
    const table = ref(null)

    const state = reactive({
      // exportDialogShow: false,
      tableData: [],
      outboundTypeFilter: undefined,
      currentPage: 1,
      total: 0,
      typeList: [],
      specsList: [],
      // buyerList: [],
      specsFilter: undefined,
      dateFilter: [],
      // buyerFilter: '',
      tableH: 0,
      activeName: 'all',
      loading: false,
      editDialogShow: false,
      currentId: undefined
    })

    onMounted(async () => {
      const res = await getTypesApi()
      state.typeList = res.data
      // state.buyerList = await getBuyerListApi()
      await getList()
      getHeight()
    })

    const getHeight = () => {
      state.tableH = table.value.$el.offsetHeight
    }

    window.onresize = () => {
      nextTick(() => {
        getHeight()
      })
    }
    const getList = async (id) => {
      try {
        state.loading = true
        const startDate = state.dateFilter ? state.dateFilter[0] : undefined
        const endDate = state.dateFilter ? state.dateFilter[1] : undefined
        const res = await getOutboundApi({
          page: state.currentPage,
          rows: 20,
          startDate,
          endDate,
          typeId: id || (state.activeName === 'all' ? undefined : state.activeName),
          specsId: state.specsFilter,
          banType: state.outboundTypeFilter
          // buyerFilter: state.buyerFilter
        })
        state.tableData = res.data
        state.total = res.total
      } catch (e) {
        $msg({
          message: e,
          type: 'error'
        })
      } finally {
        state.loading = false
      }
    }

    const handleAdd = () => {
      state.editDialogShow = true
    }

    const handleDialogclose = () => {
      state.editDialogShow = false
      state.currentId = undefined
    }

    const handleEdit = (id) => {
      state.currentId = id
      state.editDialogShow = true
    }

    const handleTabClick = async (val) => {
      state.specsFilter = undefined
      if (val.paneName === 'all') {
        getList()
      } else {
        const id = val.paneName
        getList(id)
        state.specsList = await getSpecByTypeApi({ productTypeId: id })
      }
    }

    const handleCurrentChange = async () => {
      try {
        const id = state.activeName === 'all' ? undefined : state.activeName
        await getList(id)
      } catch (e) {
        ElMessage({
          type: 'error',
          message: e
        })
      }
    }

    const handleDel = id => {
      ElMessageBox.confirm(
        '确定删除该条数据吗？',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(async () => {
          await delOutboundApi({
            id
          })
          $msg({
            message: '删除成功！',
            type: 'success'
          })
          await getList()
        })
        .catch((e) => {
          if (e !== 'cancel') {
            ElMessage({
              type: 'error',
              message: e.msg || e
            })
          }
        })
    }

    const tableRowClassName = ({ row }, rowIndex) => {
      console.log(row.outboundType)
      if (+row.outboundType === 2) {
        return 'warning-row'
      } else {
        return ''
      }
    }

    return {
      ...toRefs(state),
      getList,
      userInfo,
      table,
      handleAdd,
      handleDialogclose,
      handleEdit,
      handleCurrentChange,
      handleTabClick,
      handleDel,
      tableRowClassName
    }
  }
}
</script>

<style lang="stylus" scoped>
.stockList
  padding 15px 15px 0 15px
  width: 100%;
  height 100%
  display flex
  flex-direction: column
  /deep/.table
    height 0
    flex 1
    .warning-row
      background var(--el-color-warning-lighter)
  .pagination
    text-align: center;
    padding-bottom 0
  .red
    color red
  .green
    color #67C23A
</style>
